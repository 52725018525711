import {
  AtlasCheckbox,
  AtlasCheckboxProps,
  AtlasFieldBaseProps,
  AtlasFieldInternal,
  AtlasIcon,
} from "atlas-ds";
import { AtlasSpinner } from "atlas-ds/atlas/components/loading/AtlasSpinner/AtlasSpinner";
import { ForwardedRef, forwardRef } from "react";

export type AtlasFieldTextType =
  | "text"
  | "search"
  | "number"
  | "date"
  | "email"
  | "password"
  | "tel"
  | "time";

export type AtlasFieldTextInputMode =
  | "none"
  | "text"
  | "decimal"
  | "numeric"
  | "tel"
  | "search"
  | "email"
  | "url";

export interface AtlasFieldTextInputProps {
  ref: ForwardedRef<any>;
  id: string;
  name: string;
  value?: string;
  type?: AtlasFieldTextType;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  min?: string | number;
  max?: string | number;
  inputMode?: AtlasFieldTextInputMode;
  autoComplete?: string;
  spellCheck?: boolean;
  pattern?: string;
  "aria-invalid"?: "true" | "false";
  "aria-describedby"?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onInput?: React.FormEventHandler<HTMLInputElement>;
  onSelect?: React.ReactEventHandler<HTMLInputElement>;
}

export interface AtlasFieldTextProps
  extends AtlasFieldBaseProps<HTMLInputElement, AtlasFieldTextInputProps> {
  /**
   * La valeur du champ
   */
  value?: string;
  /**
   * Le type du champ (`text` par défaut)
   */
  type?: AtlasFieldTextType;
  /**
   * Un placeholder
   */
  placeholder?: string;
  /**
   * Le nombre de caractères minimal pour le champ
   */
  minLength?: number;
  /**
   * Le nombre de caractères maximal pour le champ
   */
  maxLength?: number;
  /**
   * Un suffixe à ajouter au champ (par exemple, le symbole € pour un coût)
   */
  suffix?: string;
  /**
   * L'information à auto-compléter
   */
  autoComplete?: string;
  /**
   * Une valeur minimale (pour les champs numériques ou de date)
   */
  min?: string | number;
  /**
   * Une valeur maximale (pour les champs numériques ou de date)
   */
  max?: string | number;
  /**
   * Un pattern à respecter pour la validation du champ
   */
  pattern?: string;
  /**
   * Le type de données saisies (principalement pour ajuster le clavier sur
   * mobile)
   */
  inputMode?: AtlasFieldTextInputMode;
  /**
   * Vérifier l'orthographe ? (activé par défaut)
   */
  spellcheck?: boolean;
  /**
   * Ajouter une icône au sein du champ
   */
  icon?: string;
  /**
   * Accompagner le champ d'une checkbox placée au-dessus
   */
  checkbox?: React.ReactElement<AtlasCheckboxProps>;
  /**
   * L'action à exécuter au focus sur ce champ
   */
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  /**
   * L'action à exécuter au focus sur ce champ
   */
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  /**
   * L'action à exécuter lors d'un input dans ce champ
   */
  onInput?: React.FormEventHandler<HTMLInputElement>;
  /**
   * L'action à exécuter lors d'une sélection dans ce champ
   */
  onSelect?: React.ReactEventHandler<HTMLInputElement>;
}

/**
 * Un champ de saisie de texte
 */
export const AtlasFieldText = forwardRef(function AtlasFieldText(
  props: AtlasFieldTextProps,
  forwardedRef: ForwardedRef<any>
) {
  const inputProps: AtlasFieldTextInputProps = {
    ref: forwardedRef,
    id: props.name,
    name: props.name,
    value: props.value,
    type: props.type,
    placeholder: props.placeholder,
    required: props.required ? true : undefined,
    disabled: props.disabled ? true : undefined,
    minLength: props.minLength,
    maxLength: props.maxLength,
    min: props.min,
    max: props.max,
    pattern: props.pattern,
    inputMode: props.inputMode,
    autoComplete: props.autoComplete,
    spellCheck: props.spellcheck,
    "aria-invalid": props.error ? "true" : undefined,
    "aria-describedby": props.error ? `${props.name}-error` : undefined,
    onChange: props.onChange,
    onFocus: props.onFocus,
    onBlur: props.onBlur,
    onInput: props.onInput,
    onSelect: props.onSelect,
  };

  const input = props.inputConstructor ? (
    props.inputConstructor(inputProps)
  ) : (
    <input {...inputProps} />
  );

  const checkbox = props.checkbox ? (
    <AtlasCheckbox {...props.checkbox.props} mini />
  ) : undefined;

  return (
    <AtlasFieldInternal {...props}>
      <div className="atlas-fieldText">
        {checkbox && (
          <div className="atlas-fieldText__checkbox">{checkbox}</div>
        )}
        <div className="atlas-fieldText__inner">
          {props.icon && (
            <div className="atlas-fieldText__icon">
              <AtlasIcon size="s" name={props.icon} />
            </div>
          )}
          {input}
          {!props.suffix && (
            <div className="atlas-fieldText__loader">
              <AtlasSpinner />
            </div>
          )}
          {props.suffix && (
            <p className="atlas-fieldText__suffix">{props.suffix}</p>
          )}
        </div>
      </div>
    </AtlasFieldInternal>
  );
});
